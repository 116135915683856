// Version: 2.8.7
.tns-outer {
	padding: 0 !important; // remove padding: clientWidth = width + padding (0) = width
	[hidden] {
		display: none !important;
	}
	[aria-controls],
	[data-action] {
		cursor: pointer;
	}
}

.tns-slider {
	-webkit-transition: all 0s;
	-moz-transition: all 0s;
	transition: all 0s;
	>.tns-item {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
}

.tns-horizontal {
	&.tns-subpixel {
		white-space: nowrap;
		>.tns-item {
			display: inline-block;
			vertical-align: top;
			white-space: normal;
		}
	}
	&.tns-no-subpixel {
		&:after {
			content: '';
			display: table;
			clear: both;
		}
		>.tns-item {
			float: left;
			margin-right: -100%;
		}
	}
}

.tns-no-calc {
	position: relative;
	left: 0;
}

.tns-gallery {
	position: relative;
	left: 0;
	min-height: 1px; // make sure slider container is visible
	// overflow: hidden;
	>.tns-item {
		position: absolute;
		left: -100%;
		-webkit-transition: transform 0s, opacity 0s;
		-moz-transition: transform 0s, opacity 0s;
		transition: transform 0s, opacity 0s;
	}
	>.tns-slide-active {
		position: relative;
	}
	&.tns-subpixel>.tns-slide-active {
		left: auto !important;
	}
	>.tns-moving {
		-webkit-transition: all 0.25s;
		-moz-transition: all 0.25s;
		transition: all 0.25s;
	}
}

.tns-lazy-img {
	-webkit-transition: opacity 0.6s;
	-moz-transition: opacity 0.6s;
	transition: opacity 0.6s;
	opacity: 0.6;
	&.loaded {
		opacity: 1;
	}
}

.tns-ah {
	-webkit-transition: height 0s;
	-moz-transition: height 0s;
	transition: height 0s;
}

.tns-ovh {
	overflow: hidden;
}

.tns-visually-hidden {
	position: absolute;
	left: -10000em;
}

.tns-transparent {
	opacity: 0;
	visibility: hidden;
}

.tns-fadeIn {
	opacity: 1;
	filter: alpha(opacity=100);
	z-index: 0;
}

.tns-normal,
.tns-fadeOut {
	opacity: 0;
	filter: alpha(opacity=0);
	z-index: -1;
}

// *** Detecting browser capability ***
$width: 310px;
$height: 10px;
$count: 70;
$perpage: 3;
.tns-t {
	&-subp2 {
		margin: 0 auto;
		width: $width;
		position: relative;
		height: $height;
		overflow: hidden;
	}
	&-ct {
		width: (100% * $count / $perpage);
		width: -webkit-calc(100% * #{$count} / #{$perpage});
		width: -moz-calc(100% * #{$count} / #{$perpage});
		width: calc(100% * #{$count} / #{$perpage});
		position: absolute;
		right: 0;
		&:after {
			content: '';
			display: table;
			clear: both;
		}
		>div {
			width: (100% / $count);
			width: -webkit-calc(100% / #{$count});
			width: -moz-calc(100% / #{$count});
			width: calc(100% / #{$count});
			height: $height;
			float: left;
		}
	}
}

.lt-ie9 .tns-controls > [aria-controls] {
  line-height: 2.5em;
}

[data-action] {
    display: block;
    margin: 10px auto;
    font-size: 17px;
    min-width: 3em;
    text-align: center;
    background: transparent;
    border: 0;
}

.tns-controls [disabled] {
    color: #999999;
    background: #B3B3B3;
	cursor: not-allowed !important;
	outline: none;
}

.tns-nav {
    text-align: center;
    margin: 2em 0;
}

.tns-nav > [aria-controls] {
    width: 9px;
    height: 9px;
    padding: 0;
    margin: 0 5px;
    border-radius: 50%;
    background: #ddd;
	border: 0;
	outline: none;
}

.tns-nav > [aria-selected="true"] {
    background: #999;
}

#controls {
	position: relative;
	list-style: none;

	li {
		cursor: pointer;
		top: 0;
		position: absolute;
		font-size: 1em;
		outline: none;
		background: black;
		color: #fff;
		text-transform: uppercase;
		font-weight: 800;
		padding: 0.5em 1em;
		border-radius: 3px;

		&.next {
			right: 0;
		}
	}
}