@import 'tinyslider';

@import url('https://fonts.googleapis.com/css?family=Muli:400,800,900');


$max-width: 980px;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Muli', sans-serif;
    font-size: 1em;
    line-height: 150%;
    font-weight: 500;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

h1, h2, h3 {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 900;
}

header {
    position: relative;
    text-align: center;

    nav {
        background: #000;
        width: 100%;
        padding: .5em 0;
        position: fixed;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        z-index: 100;

        ul {
            li {
                display: inline-block;
                margin-right: 2em;

                a {
                    text-transform: uppercase;
                    text-decoration: none;
                    color: #fff;
                    font-weight: 900;
                    letter-spacing: 0.2em;

                    &:hover {
                        border-bottom: 2px solid #fff;
                    }
                }
            }
        }
    }

    #menu-button {
        display: none;
        background: #000;
        width: 2em;
        height: 2em;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        padding: 0.5em;

        .line {
            position: relative;
            display: block;
            background: #fff;
            width: 100%;
            height: 2px;
            margin-bottom: calc(100% / 3);
        }
    }

    img {
        max-width: 350px;
    }

    .background {
        position: relative;
        background: url('../images/bg_header.jpg');
        background-size: cover;
        background-position-y: 20%;
        background-position-x: center;
        min-height: 600px;
    }

    #logo {
        position: absolute;
        bottom: 5em;
        left: 50%;
        transform: translate(-50%);
    }

    #next-concert {
        h2 {
            margin-top: 1em;
            display: inline-block;
            padding: 0.75em 1.25em;
            border: 3px solid #fff;
            font-size: 2em;
            line-height: 120%;
            font-weight: 900;
            color: #fff;
        }
    }
}

article {
    max-width: $max-width;
    margin: 0 auto;
    padding: 6em 1em;

    h2 {
        text-align: center;
        margin-bottom: 2em;

        &.white {
            color: #fff;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2em;

        img {
            z-index: -1;
        }

        h3 {
            position:relative;
            display: block;
            height: 100px;
            width: 200px;
            text-align: center;
            color: #fff;
            font-size: 2em;
            background: url('../images/triangle.png') no-repeat;
            background-position: 75% 0%;
            padding: 1.75em 0 0 0;
            margin: -2em auto 1em auto;
        }
    }
}

.story-background {
    display: block;
    width: 100%;
    height: 450px;
    background: url('../images/guitar.jpg') no-repeat;
    background-position: center;
    margin: 3em 0 -20em 0;
}

#story {
    img {
        border: 7px solid #fff;
    }

    .two {
        margin-top: 11.9em;
    }
}

#band {
    ul {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 2em;
        grid-row-gap: 2em;

        li {
            img {
                margin-bottom: 0.5em;
            }
        }
    }
}

#contact {
    p {
        display: block;
        width: 255px;
        margin: 0 auto;
        padding: 1.5em 2.5em;
        border: 3px solid #000;
    }
}

p {
    margin-bottom: 1.5em;
    letter-spacing: 0.05em;
}

hr {
    margin: 0 auto;
    width: calc(100% - 4em);
    max-width: $max-width;
    border: 0;
    height: 3px;
    background: #000;
}

.element {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2em;
}

.text {
    h3 {
        margin-bottom: 1em;
    }
}

footer {
    .copyright {
        padding: 1em;
        background: #000;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.1em;

        a {
            text-transform: uppercase;
            text-decoration: none;
            color: #fff;

            &:hover {
                border-bottom: 2px solid #fff;
            }
        }
    }
}

@import 'mediaqueries';