@media (max-width: 1024px) {
    article {
        padding: 4em 2em;
    }
}

@media (max-width: 768px) {
    header {
        .background {
            background-size: 110%;
            min-height: 470px;
        }

        #logo {
            img {
                max-width: 300px;
            }
        }

        #next-concert {
            h2 {
                font-size: 1.5em;
            }
        }
    }
}

@media (max-width: 767px) {
    header {
        nav {
            position: relative;

            ul {
                li {
                    margin: 0;
                    display: block;
                    padding: 0.5em 0;
                }
            }
        }

        #menu-button {
            display: block;
        }

        .background {
            padding: 4.5em 0;
            background-size: 110%;
            background-repeat: no-repeat;
            background-position-y: 0;
            min-height: 220px;
        }

        #logo {
            position: relative;
            transform: none;
            left: 0;
            top: 0;

            img {
                margin: 0 auto;
                max-width: 250px;
            }
        }

        #next-concert {
            h2 {
                font-size: 1.5em;
                color: #000;
                border-color: #000;
            }
        }
    }

    .element {
        grid-template-columns: 1fr;
        img {
            margin-bottom: 1em;
        }
    }

    article {
        .grid {
            display: grid;
            grid-template-columns: 1fr;
        }
    }

    .story-background {
        height: 200px;
        margin: 3em 0 -12em 0;
    }

    #story {
        .two {
            margin-top: 2em;
        }
    }

    #band {
        ul {
            grid-template-columns: 1fr;
        }
    }

    footer {
        height: auto;

        img {
            display: none;
        }
    }
}